<template>
  <v-sheet class="pa-4" :loading="loading" elevation="0">
    <v-card :loading="loading" elevation="0">
      <h4 class="title">Файлы проекта</h4>
      <v-progress-circular v-if="loading" class="mt-5 mx-auto" color="primary" indeterminate width="2"/>
      <FileUpload v-else :read-only="!project.permissions.can_add_files"
                  :value="$store.state.projects.currentProjectFiles" class="mt-3 mb-3"
                  multiple @input="save"/>
    </v-card>
  </v-sheet>
</template>

<script>
import FileUpload from "@/components/Common/FileUpload.vue";
import project_files from "@/api/project_files.js";

export default {
  name: "ProjectsItemFilesComponent",
  components: {FileUpload},
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    },

    readOnly() {
      return true
    }
  },
  data() {
    return {
      loading: true,
      addFileDialog: false
    }
  },
  methods: {
    download(file) {
      window.location = file.path;
    },
    save(files) {
      project_files.store(this.$store.state.projects.currentProject.id, {files: files.map(f => f.id)});

    },
  },
  mounted() {
    if (!this.$store.state.projects.currentProject.permissions.can_see_files) {
      this.$router.back()
      return;
    }
    this.$store.dispatch('getProjectFiles', {project_id: this.$route.params.project_id}).then(() => {
      this.loading = false;
    })

  }
}
</script>

<style scoped>

</style>
